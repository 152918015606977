<template>
    <div>
<!--    &lt;!&ndash; preloader area start &ndash;&gt;-->
<!--    <div class="preloader" id="preloader">-->
<!--        <div class="preloader-inner">-->
<!--            <div class="spinner">-->
<!--                <div class="dot1"></div>-->
<!--                <div class="dot2"></div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="body-overlay" id="body-overlay"></div>-->
<!--    &lt;!&ndash; preloader area end &ndash;&gt;-->

        <navbar-component></navbar-component>
        <div class="pd-top-120">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h4 class="title">{{ translate('messages.heading.training') }} > {{ slug.name }}</h4>
                    </div>
                </div>
                <div class="row">
                    <section class="single-blog-area pt-5 pd-bottom-80">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="blog-details-inner">
                                        <div class="thumb mb-4">
                                            <img :src="`${ slug.image }`" alt="img">
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-12 col-lg-4 col-xl-4">
                                                <div class="sidebar-area">
                                                    <div class="widget widget-category">
                                                        <h5 class="widget-title">Category</h5>
                                                        <ul class="list">
                                                            <li v-for="category in categories" :key="category.id"><a @click="getDescribe(category.slug)"><i class="fa fa-caret-right"></i>{{ category.name }}</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 col-lg-8 col-xl-8">
                                                <div class="blog-details-wrap">
<!--                                                    <p>{{ slug.desc }}</p>-->

                                                    <blockquote class="blockquote">
                                                        <p class="mb-0" v-html="slug.header"></p>
                                                    </blockquote>

                                                    <p class="mb-4" v-html="slug.body"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
            </div>
        </div>
        <footer-component></footer-component>
    </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import {ROAST_CONFIG} from "../config";
export default {
    name: "app",
    components: {
        NavbarComponent,
        FooterComponent
    },
    data(){
        return {
            'slug' :[],
            'categories' : [],
            'lang' : '',
        }
    },
    watch: {
        $route(after, before) {
            this.getSlug();
        }
    },
    methods: {
        getDescribe(slug) {
            const lang = this.$route.params.locale ;
            this.$router.push({name:'training',params:{slug:slug,lang:lang}})
            // this.$router.push({
            //     path: `/training/${slug}`,
            // })
        },
        getSlug: function () {
            //console.log(this.$route.params.locale);
            let params = this.$route.params.slug ;
            let locale = this.$route.params.locale ;
            //let locale = this.$route.params.locale ;
            //$route.params.slug
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/training/'+ params)
                .then(response => {
                    this.slug = response.data.data;
                }).catch(error => {
                //console.log(error);

            });
        },
        getAllSlug: function () {
            //let params = this.$route.params.slug ;
            let locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/training')
                .then(response => {
                    this.categories = response.data.data;
                    //console.log(this.categories);
                }).catch(error => {
                //console.log(error);

            });
        },
        getLang: function () {
            let locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/get')
                .then(response => {
                    this.lang = response.data.lang;
                    //console.log(this.lang);
                }).catch(error => {
                console.log(error);

            });
        }
    },
    mounted: function () {
        this.getLang();
        this.getSlug();
        this.getAllSlug();

    },
}
</script>
