<template>
    <div>
<!--    &lt;!&ndash; preloader area start &ndash;&gt;-->
<!--    <div class="preloader" id="preloader">-->
<!--        <div class="preloader-inner">-->
<!--            <div class="spinner">-->
<!--                <div class="dot1"></div>-->
<!--                <div class="dot2"></div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="body-overlay" id="body-overlay"></div>-->
<!--    &lt;!&ndash; preloader area end &ndash;&gt;-->

        <navbar-component></navbar-component>
        <!-- Product-Area Start-->

        <div class="pd-top-120 text-center error-404 not-found">
            <div class="container p-2">
                <h1 class="title-404">404</h1>
                <h1 class="title">Opps! This page Could Not Be Found!</h1>
                <p class="subtitle">Sorry bit the page you are looking for does not exist, have been removed or name changed</p>
            </div>
        </div>

        <footer-component></footer-component>
    </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
export default {
    name: "app",
    components: {
        NavbarComponent,
        FooterComponent
    }
}
</script>
