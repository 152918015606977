

require('./bootstrap');
import Vue from 'vue';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);
import { ROAST_CONFIG } from './config.js';
import router from './router.js';
window.tranlate=require('./VueTranslation/Translation.js').default.translate;

export default new Vue({
    el: '#app',
    router,
});
