<template>
    <div>
        <div class="pd-top-120">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h4 class="title">{{ translate('messages.heading.clients-and-partners') }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <navbar-component></navbar-component>
        <div class="pd-top-120">
            <div class="container">
                <div class="row">
                    <section class="single-blog-area pt-5 pd-bottom-80">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="thumb mb-4 col-lg-3 col-xl-3 col-sm-12 col-md-12" v-for="partner in partners">
                                            <img :src="`${ partner.image }`" alt="img" class="max-h-full md:max-h-screen  w-screen">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
            </div>
        </div>
        <footer-component></footer-component>
    </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import {ROAST_CONFIG} from "../config";
export default {
    name: "app",
    components: {
        NavbarComponent,
        FooterComponent
    },
    data(){
        return {
            'partners' : [],
        }
    },
    methods: {
        getPartners: function () {
            //let params = this.$route.params.slug ;
            const lang = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + lang + '/partners')
                .then(response => {
                    this.partners = response.data.data;
                    //console.log(this.partners);
                }).catch(error => {
                //console.log(error);

            });
        }
    },
    mounted: function () {
        this.getPartners();
        //console.log(this.$route.name);

    },
}
</script>
