<template>
    <div><navbar-component></navbar-component>
        <div v-if="general.id===1">
            <div class="pd-top-120">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="title"> {{ general.name }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <article v-for="article in articles" :key="article.id">
                <div :class="`${ article.background }`">
                    <div class="container pd-top-120 pd-bottom-110">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="contents">
                                    <h3 class="mt-2">{{ article.name }}</h3>
                                    <blockquote class="blockquote">
                                        <p class="mb-0" v-html="article.desc"></p>
                                    </blockquote>
                                </div>

                            </div>
                            <div class="col-lg-4">
                                <div class="about-img">
                                    <img :src="`${ article.image }`" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

        </div>


        <div v-if="general.id===2">
            <div class="pd-top-120">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="title"> {{ general.name }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <article v-for="article in articles" :key="article.id">
                <div :class="`${ article.background }`">
                    <div class="container pd-top-120 pd-bottom-110">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="about-img align-self-center">
                                    <img :src="`${ article.image }`" alt="img">
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="contents">
                                    <h3 class="mt-2">{{ article.name }}</h3>
                                    <blockquote class="blockquote">
                                        <p class="mb-0" v-html="article.desc"></p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        <div v-if="general.id===3">
            <div class="pd-top-120">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="title"> {{ general.name }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <article v-for="article in articles" :key="article.id">
                <div :class="`${ article.background }`">
                    <div class="container pd-top-120 pd-bottom-110">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="contents">
                                    <h3 class="mt-2">{{ article.name }}</h3>
                                    <blockquote class="blockquote">
                                        <p class="mb-0" v-html="article.desc"></p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>

        <footer-component></footer-component>
    </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import {ROAST_CONFIG} from "../config";
export default {
    name: "app",
    components: {
        NavbarComponent,
        FooterComponent
    },
    data(){
        return {
            'general' :[],
            'articles' : [],
        }
    },
    watch: {
        $route(after, before) {
            this.getMenu();
            this.getArticles();
        }
    },
    methods: {
        getMenu: function () {
            let slug = this.$route.params.slug ;
            const lang = this.$route.params.locale ;
            //console.log(params);
            axios.get(ROAST_CONFIG.API_URL + '/' + lang + '/general/'+ slug)
                .then(response => {
                    this.general = response.data.data;
                    //console.log(this.general);
                }).catch(error => {
                //console.log(error);

            });
        },
        getArticles: function () {
            let slug = this.$route.params.slug ;
            const lang = this.$route.params.locale ;
            //console.log(params);
            axios.get(ROAST_CONFIG.API_URL + '/' + lang + '/articles/'+ slug)
                .then(response => {
                    this.articles = response.data.data;
                    //console.log(this.articles);
                }).catch(error => {
                //console.log(error);

            });
        },

    },
    mounted: function () {
        this.getMenu();
        this.getArticles();

    },
}
</script>
