/**
 * Defines the API route we are using.
 */
var api_url = '';

switch( process.env.NODE_ENV ){
    case 'development':
        api_url = 'http://www.a-list.com.hk.local:8085/api/v1';
        //console.log('Welcome to dev');

        break;
    case 'production':
        api_url = 'https://www.a-list.com.hk/api/v1';
        //console.log('Welcome to production');
        break;
}

export const ROAST_CONFIG = {
    API_URL: api_url,
}
