<!--suppress SpellCheckingInspection -->
<template>
    <div>
        <navbar-component></navbar-component>
<!--        <section class="banner-area banner-area-2">-->
<!--            <div class="banner-slider owl-carousel owl-theme">-->
<!--                &lt;!&ndash; slider !&ndash;&gt;-->
<!--                <div class="item" v-for="data in this.sliders" :style="{backgroundImage: 'url(' + data.url + ')' }">-->
<!--                    <div class="container">-->
<!--                        <div class="row">-->
<!--                            <div class="col-lg-5 offset-lg-7 align-self-center">-->
<!--                                <div class="banner-inner">-->
<!--                                    <h6>WELLCOME TO A-LIST</h6>-->
<!--                                    <h1>{{ translate('messages.foo.bar') }}</h1>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;slider !&ndash;&gt;-->

<!--            </div>-->
<!--        </section>-->
        <vueper-slides :slide-ratio="1 / 2" fixed-height="1000px" fade :touchable="false">
            <vueper-slide v-for="(slider, i) in this.sliders"
                          :title="slider.name"
                          :content="slider.title"
                          :key="i"
                          :style="'color: ' + ['#000']+';'+'font-size:'+['40px']"
                          :image="slider.url" />
        </vueper-slides>
        <!-- Banner Area End -->

        <!-- Product-Area Start-->
        <section class="product-area pd-top-70 pd-bottom-80">
            <div class="container">
                <div class="row mt-5">
                    <div class="col-lg-12">
                        <div class="section-title-2 text-center">
                            <h3 class="title">{{ translate('messages.home-component.clients-and-partners') }}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <vueper-slides
                            class="no-shadow"
                            :visible-slides="4"
                            :slide-ratio="1 / 10"
                            :dragging-distance="70">
                            <vueper-slide
                                v-for="data in partners"
                                :key="data.id"
                                :title="data.name"
                                :image="data.image"
                                :style="'color: ' + ['#black']+';'+'opacity:'+['0.2']+';'+'font-size:'+['20px']"/>
                        </vueper-slides>

                    </div>
                </div>
            </div>
        </section>

        <footer-component></footer-component>
    </div>
</template>
<script>
import NavbarComponent from "../components/Navbar";
import FooterComponent from "../components/Footer";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {ROAST_CONFIG} from "../config";
window.Vue = require('vue');
Vue.prototype.translate=require('../VueTranslation/Translation.js').default.translate;
export default {
    name: "app",
    components: {
        NavbarComponent,
        FooterComponent,
        VueperSlides,
        VueperSlide
    },
    data(){
        return {
            'partners' :[],
            'lang' : '',
            'sliders' : [],
        }
    },
    methods: {
        getPartners: function () {
            let locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/partners')
                .then(response => {
                    this.partners = response.data.data;
                    //console.log(this.partners);
                }).catch(error => {
                    //console.log(error);

            });
        },
        getSlider: function () {
            let locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/sliders')
                .then(response => {
                    this.sliders = response.data.data;
                    //console.log(this.sliders);
                }).catch(error => {
                console.log(error);

            });
        },
        getLang: function () {
            let locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/get')
                .then(response => {
                    this.lang = response.data.lang;
                    //console.log(this.lang);
                }).catch(error => {
                //console.log(error);

            });
        }
    },
    mounted: function () {
        //console.log(this.partners);
        this.getPartners();
        this.getSlider();
        this.getLang();
    },
}
</script>
