<template>
    <div>
    <footer>
        <!--Footer bottom-->
        <div class="footer-bottom footer-bottom-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="copyright-area">
                            <p class="m-0 p-0">{{ translate('messages.footer_text') }}</p>
                            <p class="">{{ translate('messages.powered_by') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Footer bottom-->

        <!-- back to top area start -->
        <div class="back-to-top">
            <span class="back-top"><i class="la la-arrow-up"></i></span>
        </div>
        <!-- back to top area end -->
    </footer>
    </div>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>
