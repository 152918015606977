<template>
 <div>
    <!-- <div class="preloader" id="preloader">-->
<!--         <div class="preloader-inner">-->
<!--             <div class="spinner">-->
<!--                 <div class="dot1"></div>-->
<!--                 <div class="dot2"></div>-->
<!--             </div>-->
<!--         </div>-->
<!--     </div>-->
<!--     <div class="body-overlay" id="body-overlay"></div> -->

     <!-- search popup area start -->
     <!-- <div class="search-popup" id="search-popup">
         <form action="#" class="search-form">
             <div class="form-group">
                 <input type="text" class="form-control" placeholder="Search.....">
             </div>
             <button type="submit" class="submit-btn"><i class="fa fa-search"></i></button>
         </form>
     </div> -->
     <!-- //. search Popup -->

     <!-- navbar start -->
     <div class="navbar-area mb-10">
         <nav class="navbar navbar-expand-lg">
             <div class="container nav-container">
                 <div class="responsive-mobile-menu">
                     <button class="menu toggle-btn d-block d-lg-none" data-target="#themefie_main_menu"
                             aria-expanded="false" aria-label="Toggle navigation">

                         <span class="icon-left"></span>
                         <span class="icon-right"></span>
                     </button>
                 </div>
                 <div class="logo">
                     <a class="main-logo" href="#"><img src="/assets/img/logo.png" alt="img"></a>
                 </div>
                 <div class="nav-right-part nav-right-part-mobile">
                     <ul>
                         <li><a class="shopping-cart-btn" href="#" @click="switchLang()">{{ translate('messages.menu.locale') }}</a></li>
                     </ul>
                 </div>
                 <div class="collapse navbar-collapse" id="themefie_main_menu">
                     <ul class="navbar-nav menu-open">
                         <li><a href="#" @click="getHome()">{{ translate('messages.menu.home') }}</a></li>
                         <li class="menu-item-has-children current-menu-item">
                             <a href="#">{{ translate('messages.menu.about') }}</a>
                             <ul class="sub-menu">
                                 <li v-for="data in menubar" :key="data.id"><a href="#" @click="getMenu(data.slug)">{{ data.name }}</a></li>
                             </ul>
                         </li>
                         <li class="menu-item-has-children current-menu-item">
                             <a href="#">{{ translate('messages.menu.training') }}</a>
                             <ul class="sub-menu">
                                 <li v-for="data in categories" :key="data.id"><a href="#" @click="getDescribe(data.slug)">{{ data.name }}</a></li>
                             </ul>
                         </li>
<!--                         <li class="menu-item-has-children current-menu-item">-->
<!--                             <a href="#">{{ translate('messages.menu.partner') }}</a>-->
<!--                             <ul class="sub-menu">-->
<!--                                 <li v-for="data in partners" :key="data.id"><a href="#" @click="getPartner(data.id)">{{ data.name }}</a></li>-->
<!--                             </ul>-->
<!--                         </li>-->

                         <li>
                         <li><a href="#" @click="getPartners()">{{ translate('messages.menu.partner') }}</a></li>
                         <li><a href="#" @click="getContact()">{{ translate('messages.menu.contact') }}</a></li>
                     </ul>
                 </div>
                 <div class="nav-right-part nav-right-part-desktop">
                     <ul>
<!--                         <li><a class="search header-search" href="#"><i class="fa fa-search"></i></a></li>-->
                         <li><a class="shopping-cart-btn" href="#" @click="switchLang()">{{ translate('messages.menu.locale') }}</a></li>
                     </ul>
                 </div>
             </div>
         </nav>
     </div>
     <!-- navbar end -->
 </div>
</template>
<script>
import {ROAST_CONFIG} from "../config";

export default {
    data() {
        return {
            categories: '',
            partners: [],
            menubar: [],
            lang: '',
        };
    },
    methods: {
        getHome() {
            const lang = this.$route.params.locale ;
            //this.$router.push({name:'home',params:{lang:lang}})
            location.href = '/' + lang  + '/home' ;
        },
        getContact() {
            const lang = this.$route.params.locale ;
            //this.$router.push({name:'contact',params:{lang:lang}})
            location.href = '/' + lang  + '/contact' ;
        },
        getPartners() {
            const lang = this.$route.params.locale ;
            //this.$router.push({name:'contact',params:{lang:lang}})
            location.href = '/' + lang  + '/partner' ;
        },
        switchLang() {
            const lang = this.$route.params.locale ;
            if (lang === "en") {
                //this.$router.push({name:'home',params:{lang:'zh-Hant'}})
                //console.log(lang)
                location.href = '/zh-Hant/home' ;
            }else{
                location.href = '/en/home' ;
            }
        },
        getDescribe(slug) {
            const lang = this.$route.params.locale ;
            this.$router.push({name:'training',params:{slug:slug,lang:lang}})
        },
        getMenu(slug) {
            const lang = this.$route.params.locale ;
            this.$router.push({name:'general',params:{slug:slug,lang:lang}})
            //console.log(123);
        },
        // getPartner(id) {
        //     const lang = this.$route.params.locale ;
        //     this.$router.push({name:'partner',params:{id:id,lang:lang}})
        // },
        getAllSlug: function () {
            const locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/training')
                .then(response => {
                    this.categories = response.data.data;
                }).catch(error => {
                //console.log(error);

            });
        },
        // getAllPartners: function () {
        //     const locale = this.$route.params.locale ;
        //     axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/partners')
        //         .then(response => {
        //             this.partners = response.data.data;
        //             //console.log(this.partners);
        //         }).catch(error => {
        //         //console.log(error);
        //
        //     });
        // },
        getLang: function () {
            let locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/get')
                .then(response => {
                    this.lang = response.data.lang;
                    //console.log(this.lang);
                }).catch(error => {
                console.log(error);

            });
        },
        getGeneralMenu: function () {
            const locale = this.$route.params.locale ;
            axios.get(ROAST_CONFIG.API_URL + '/' + locale + '/general')
                .then(response => {
                    this.menubar = response.data.data;
                    //console.log(this.menubar);
                }).catch(error => {
                //console.log(error);

            });
        },
    },
    mounted: function () {
        this.getLang();
        this.getAllSlug();
        // this.getAllPartners();
        this.getGeneralMenu();

    },
};
</script>
