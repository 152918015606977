<template>
    <div>
<!--    &lt;!&ndash; preloader area start &ndash;&gt;-->
<!--    <div class="preloader" id="preloader">-->
<!--        <div class="preloader-inner">-->
<!--            <div class="spinner">-->
<!--                <div class="dot1"></div>-->
<!--                <div class="dot2"></div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="body-overlay" id="body-overlay"></div>-->
<!--    &lt;!&ndash; preloader area end &ndash;&gt;-->

        <navbar-component></navbar-component>
        <div class="contact-map">
            <div class="mapouter">
                <div class="gmap_canvas"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.436671723544!2d114.14647895082173!3d22.337134885232377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340407548be76b89%3A0xc47a08a4488119da!2sTower%201%2C%20Cheung%20Sha%20Wan%20Plaza%2C%20833%20Cheung%20Sha%20Wan%20Rd%2C%20Cheung%20Sha%20Wan!5e0!3m2!1sen!2shk!4v1607775715054!5m2!1sen!2shk" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe></div>
            </div>
        </div>
        <!-- contact-Area Start-->
        <section class="contact-area pd-top-80 pd-bottom-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-6">
                        <div class="contact-info-inner">
                            <h4></h4>
                            <div class="single-contact-wrap">
                                <span class="icon"><i class="far fa-envelope"></i></span>
                                <span>{{ translate('messages.contact_form.email') }}</span>
                                <p>{{ translate('messages.contact_form.email_text') }}</p>
                            </div>
                            <div class="single-contact-wrap">
                                <span class="icon"><i class="fa fa-phone-alt"></i></span>
                                <span>{{ translate('messages.contact_form.phone') }}</span>
                                <p>{{ translate('messages.contact_form.phone_text') }}</p>
                                <p>{{ translate('messages.contact_form.fax_text') }}</p>
                            </div>
                            <div class="single-contact-wrap">
                                <span class="icon"><i class="far fa-id-badge"></i></span>
                                <span>{{ translate('messages.contact_form.licence_no') }}</span>
                                <p>{{ translate('messages.contact_form.licence_no_text') }}</p>
                            </div>
                            <div class="single-contact-wrap">
                                <span class="icon"><i class="fa fa-map-marker-alt"></i></span>
                                <span>{{ translate('messages.contact_form.address') }}</span>
                                <p>{{ translate('messages.contact_form.address_text') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6">
                        <form class="contact-form">
                            <h6>{{ translate('messages.contact_form.sub_title') }}</h6>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="single-input-wrap">
                                        <label>{{ translate('messages.contact_form.your_name') }}</label>
                                        <input type="text" class="single-input">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="single-input-wrap">
                                        <label>{{ translate('messages.contact_form.your_email') }}</label>
                                        <input type="email" class="single-input">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="single-input-wrap">
                                        <label>{{ translate('messages.contact_form.phone_number') }}</label>
                                        <input type="number" class="single-input">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="single-input-wrap">
                                        <label>{{ translate('messages.contact_form.company') }}</label>
                                        <input type="text" class="single-input">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="single-input-wrap single-textarea-wrap">
                                        <label>{{ translate('messages.contact_form.contents') }}</label>
                                        <textarea class="single-input textarea"></textarea>
                                    </div>
                                </div>
                            </div>
                            <a class="btn btn-black" href="#">{{ translate('messages.contact_form.submit') }}</a>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- Product-Area End-->
        <footer-component></footer-component>
    </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
export default {
    name: "app",
    components: {
        NavbarComponent,
        FooterComponent
    }
}
</script>
