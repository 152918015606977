import Vue from 'vue';
import Router from 'vue-router';

// 引用頁面的 Component
import home from './views/Home';
import contact from './views/ContactUs';
import general from './views/General';
import training from './views/Training';
// import partner from './views/Partner';
import partners from './views/Partners';
import NotFound from "./views/NotFound";

// 使用 Vue Router
Vue.use(Router);
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// Route 設定
export const routes = [
    { path: '/:locale/home', component: home, name:'home'},
    { path: '/:locale/contact', component: contact, name: 'contact'},
    { path: '/:locale/partner', component: partners, name: 'partners'},
    { path: '/:locale/g/:slug', component: general, name: 'general'},
    // { path: '/:locale/policy', component: policy, name: 'policy'},
    { path: '/:locale/training/:slug', component: training, name: 'training' },
    // { path: '/:locale/partner/:id', component: partner, name: 'partner'},
    { path: '/:locale/*', component: NotFound },
];

// 建立 Vue Router instance
const router = new Router({
    mode: 'history',
    routes
});

export default router;
